// local variables file
@import "_variables.scss";

// later on it's usually better to use variables from the skins
// so that you can re-build the css using the same values used in the skin
// it would then look a bit like this:
// @import "../../../_default/skins/2sxc-bootstrap-skin/src/_variables.scss";

// Size of the popup
$popup-width: 600px;
$popup-height: 400px;

@media all {
	.fancybox-wrap.popup-fancybox {
		top: auto !important;
		bottom: 20px !important;
		left: 20px !important;
	}
  .app-popup {
		display: none;

		.popup {
			background: #ffffff;
			display: block;
			overflow: hidden;

			// @media (max-height: $popup-height) {

			// 	top: 20px;
			// 	min-height: 400px;
			// 	overflow-y: scroll;
			// }
			// @media (max-width: $popup-width) {
			// 	max-width: calc(100vw - 30px);
			// 	left: 10px;
			// 	top: 75px;
			// 	overflow-y: scroll;
			// 	max-height: calc(100vh - 90px);
			// }

			// @media (max-width: 320px) {
			// 	overflow-y: scroll;
			// 	max-height: calc(100vh - 90px);
			// }
			.popup-image {
				position: relative;				
			}

			.popup-content {
				padding: 40px;
				padding-top:30px;

				h1,
				h2,
				h3,
				h4,
				h5,
				h6 {
					margin-top: 0;
				}
			}

			.popup-close {
				height: 20px;
				width: 20px;
				position: absolute;
				z-index: 850;
				right: 10px;
				top: 10px;
				cursor: pointer;
				padding: 0 6px;
				border-radius: 50%;

				&:after {
					content: "✖";
					line-height: 20px;
					position: absolute;
					font-size: 18px;
					color: #000;
					transition: transform 0.6s;
					}

				&:hover {
					&:after {
						color: #000;
						transform: rotate(180deg);
					}
				}
			}
		}

		&.admin {
			display: none;
		}
	}

	#app-popup-open-menu > span:first-of-type {
		vertical-align: top;
	}

	.menu-close {
		height: 20px;
		width: 20px;
		position: absolute;
		z-index: 850;
		right: 20px;
		top: 20px;
		cursor: pointer;

		&:after {
			content: "✖";
			line-height: 20px;
			position: absolute;
			font-size: 20px;
			color: #666;
			transition: transform .3s ease-in-out;
		}

		&:hover {
			&:after {
				color: #000;
				-moz-transform: rotate(30deg);
				-ms-transform: rotate(30deg);
				-o-transform: rotate(30deg);
				-webkit-transform: rotate(30deg);
			}
		}
	}
	// Setting Stylings
	.setting-wrapper {
		$settingscolor: #000;
		margin: 15px 0;
		background: lighten($settingscolor, 10%);
		color: #999;
		border-bottom-right-radius: 8px;
		width: 320px;
		position: fixed;
		left: -240px;
		top: 200px;
		box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.3);
		transition: left 0.3s ease-in-out;
		&.active {
			left: 80px;
		}
		#setting-btn {
			position: absolute;
			cursor: pointer;
			right: -40px;
			top: 0;
			width: 40px;
			height: 40px;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			background: $settingscolor url(gear.png) center 12px no-repeat;
			color: #fff;
			box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.3);
		}
		.Setting-Title {
			padding: 12px 24px;
			border-bottom: 0px solid rgba(255, 255, 255, 0.6);
			background: $settingscolor;
			color: #fff;
			line-height: 18px;
			h5 {
				margin: 0;
				font-size: 16px;
				font-weight: normal;
			}
			small {
				font-size: 14px;
				color: rgba(255, 255, 255, 0.6);
			}
		}
		.setting-item {
			padding: 12px 24px;
			border-bottom: 1px solid lighten($settingscolor, 20%);
			font-size: 14px;
			line-height: 18px;

			&:last-child {
				border-bottom: 0px;
			}
			.btn {
				background-color: #0088f4 !important;
				color: #fff;
				cursor: pointer;
				&:hover {
					background-color: #000 !important;
					color: #fff;
				}
			}
		}
	}
}
